<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">
        <div class="bg-white p-4">
            <h4>User Management</h4>

            <div class="d-flex mb-4" style="column-gap: 10px">
                <div class="flex-fill">
                    <input type="search" class="form-control" placeholder="Search user by name or email">
                </div>
                <div class="">
                    <router-link to="to" class="btn btn-primary">Create User</router-link>
                </div>
            </div>

            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>E-Mail</th>
                    <th>Phone</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in users" :key="user.id">
                    <td>{{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.phone }}</td>
                    <td>
                        <router-link to="" class="btn btn-sm btn-light">Edit</router-link>
                        <router-link :to="'/users/permissions/'+ user.id" class="btn btn-sm btn-info">Permissions
                        </router-link>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "Users",
    data() {
        return {
            users: []
        }
    },
    mounted() {
        this.loadUsers()
    },
    methods: {
        loadUsers() {
            this.$http.get('/api/users/manage').then(response => {
                this.users = response.data.data
            })
        }
    }
}
</script>

<style scoped>

</style>